import axios from "../../axios_orders";

import {
  GET_PROJECTS_FAILURE,
  GET_PROJECTS_START,
  GET_PROJECTS_SUCCESS,
} from "./constants";

export const getProjectsAjax = async (dispatch) => {
  try {
    dispatch({ type: GET_PROJECTS_START });
    const res = await axios.get("/project");
    dispatch({ type: GET_PROJECTS_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: GET_PROJECTS_FAILURE });
  }
};
