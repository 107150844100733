import React from 'react'

// Modules
import ReactModal from "react-modal";

  // Modal
  ReactModal.setAppElement("#root");

const ErrorModal = (props) => {
    return (
        <ReactModal
        isOpen={props.modalIsOpen}
        closeTimeoutMS={350}
        contentLabel="Error Modal"
        className="modal w-full h-full sm:h-auto sm:w-2/3 md:w-1/2 xl:w-1/3 rounded shadow"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          props.closeModalHandler();
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col p-10 bg-white rounded">
          <h2 className="font-bold text-xl mb-4">Oh there!</h2>
          <p className="font-normal text-lg" >{props.error && props.error}</p>
          {/* <h3>{logoutInfo && logoutInfo}</h3> */}
          {/* <h3>{unlinkInfo && unlinkInfo}</h3> */}

          {/* Buttons */}
          {/* {logoutInfo && (
            <button
              onClick={logout}
              className="font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-gray-600 border border-transparent rounded-lg active:bg-gray-600 hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray"
            >
              Yes
            </button>
          )} */}

          {/* {unlinkInfo && (
            <button
              onClick={unlinkDevices}
              className="font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-gray-600 border border-transparent rounded-lg active:bg-gray-600 hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray"
            >
              Yes
            </button>
          )} */}

          {/* Always Close Button */}
          <button
            onClick={props.closeModalHandler}
            className="font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-gray-600 border border-transparent rounded-lg active:bg-gray-600 hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray"
          >
            Close
          </button>
        </div>
      </ReactModal>
    )
}

export default ErrorModal
