import React from "react";

// Modules
import axios from "../axios_orders";
import { useHistory, useLocation } from "react-router-dom";

// Components
import ShowLogin from "../components/ShowLogin";
import ShowRegister from "../components/ShowRegister";
import LoadingOverlay from "../components/LoadingOverlay";

// Actions
import { getProjectsAjax } from "../context/Project/actions";
import { getNotificationsAjax } from "../context/Notification/actions";

// Consts
import { SET_USER } from "../context/Auth/constants";

// Keys
import keys from "../util/keys";

// Context
import { AuthContext } from "../context/Auth/context";
import { ProjectContext } from "../context/Project/context";
import { NotificationContext } from "../context/Notification/context";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Login = (props) => {
  // Context
  const { authDispatch } = React.useContext(AuthContext);
  const { projectDispatch } = React.useContext(ProjectContext);
  const { notificationDispatch } = React.useContext(NotificationContext);

  // State
  const [showLogin, setShowLogin] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [errors, setErrors] = React.useState({
    email: "",
    password: "",
    general: "",
    confirmPassword: "",
  });

  // History
  const history = useHistory();
  const query = useQuery();
  
  React.useEffect(() => {
  
    if(query.get('type') === 'success') {
      setMessage("Thank you for signing up for the pro plan! \n\n Sign up or login using Google with the email you used for the payment!")
      setShowLogin(false);
    }


  }, [query]);

  const inputHandler = (name, event) => {
    setErrors({ email: "", password: "", general: "", confirmPassword: "" });
    switch (name) {
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "confirmPassword":
        setConfirmPassword(event.target.value);
        break;

      default:
        break;
    }
  };

  const handleLoginGoogle = async (googleData) => {
    setLoading(true);
    axios
      .post("/users/google", {
        token: googleData.tokenId,
      })
      .then((res) => {
        // Axios
        axios.defaults.headers.common["Authorization"] = res.data.token;
        // Save In LocalStorage for persisten login
        const token = res.data.token;
        localStorage.setItem(keys.session, token);
        axios
          .get("/users/me")
          .then(async (res) => {
            authDispatch({ type: SET_USER, payload: res.data.user });
            await getProjectsAjax(projectDispatch);
            await getNotificationsAjax(notificationDispatch, "");
            history.push("/");
            setLoading(false);
          })
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data) {
          setErrors({ ...error.response.data });
        }
        setLoading(false);
      });
    // store returned user somehow
  };

  const signUpHandler = (event) => {
    event.preventDefault();
    setMessage("");
    setLoading(true);
    const userData = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    };
    axios
      .post("/users/register", userData)
      .then(async (response) => {
        setLoading(false);
        loginHandler(event);
        setMessage("Thank you for signing up! Login by pressing below!");
        setShowLogin(true);
      })
      .catch((error) => {
        if (error.response.data) {
          setErrors({ ...error.response.data });
        }
        setLoading(false);
      });
  };

  const loginHandler = async (event) => {
    event.preventDefault();
    setMessage("");
    setLoading(true);
    const userData = {
      email: email,
      password: password,
    };
    axios
      .post("/users/login", userData)
      .then(async (res) => {
        setLoading(false);
        // Axios
        axios.defaults.headers.common["Authorization"] = res.data.token;
        // Save In LocalStorage for persisten login
        const token = res.data.token;
        localStorage.setItem(keys.session, token);
        axios
          .get("/users/me")
          .then(async (res) => {
            authDispatch({ type: SET_USER, payload: res.data.user });
            await getProjectsAjax(projectDispatch);
            await getNotificationsAjax(notificationDispatch, "");
            history.push("/");
            setLoading(false);
            history.push("/");
          })
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data) {
          setErrors({ ...error.response.data });
        }
        setLoading(false);
      });
  };

  return (
    <div className="bg-white font-family-karla h-screen">
      {/* {loading ? <p>loading..</p> : <p>Not loading..</p>} */}
      {loading && <LoadingOverlay />}

      <div className="w-full flex flex-wrap">
        <div className="w-full flex flex-col">
          {/* Login or Register */}

          {showLogin ? (
            <ShowLogin
              showLogin={showLogin}
              setShowLogin={setShowLogin}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              loginHandler={loginHandler}
              inputHandler={inputHandler}
              message={message}
              errors={errors}
              handleLoginGoogle={handleLoginGoogle}
            />
          ) : (
            <ShowRegister
            message={message}
              showLogin={showLogin}
              setShowLogin={setShowLogin}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              confirmPassword={confirmPassword}
              setConfirmPassword={setConfirmPassword}
              signUpHandler={signUpHandler}
              inputHandler={inputHandler}
              errors={errors}
              handleLoginGoogle={handleLoginGoogle}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
