import React from "react";

import { ProjectContext } from "../context/Project/context";

import { ICONS } from "../util/icons";

export const TYPES = {
  info: "info",
  warning: "warning",
  error: "error",
  success: "success",
};

function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const NotificationItem = (props) => {
  const { projectState } = React.useContext(ProjectContext);

  let statusColor = "blue";

  switch (props.item.type) {
    case TYPES.info:
      statusColor = "blue";
      break;

    case TYPES.warning:
      statusColor = "yellow";
      break;

    case TYPES.error:
      statusColor = "red";
      break;

    case TYPES.success:
      statusColor = "green";
      break;

    default:
      break;
  }

  let project;

  if (props.item.project) {
    project = projectState.projects.find((x) => x._id === props.item.project);
  }

  return (
    <div
      className="block text-gray-700 dark:text-gray-400 transition-all md:flex md:justify-between hover:bg-gray-100 cursor-pointer"
      onClick={() => props.setActiveNoti(props.item)}
    >
      <div className="flex justify-between">
        <div className="px-4 py-3 w-64">
          <div className="mb-2 text-xs block md:hidden">
            {new Date(props.item.created).toLocaleString()}
          </div>
          <div className="text-sm flex">
            <div className="relative w-2 h-8 mr-3 rounded-full md:block">
              <div className={`w-1 h-full rounded bg-${statusColor}-500`}></div>
            </div>
            <div>
              {project && (
                <p className="text-xs text-black mt-0 mb-0">{project.name}</p>
              )}
              <p className="font-semibold mb-0">{props.item.message}</p>
              <p className="text-xs text-gray-600 dark:text-gray-400">
                {props.item.description}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-3 text-xs hidden md:block">
        <div
          className={`px-4 py-1 font-semibold leading-tight rounded-full text-${statusColor}-700 bg-${statusColor}-100 w-24 text-center `}
        >
          {jsUcfirst(props.item.type)}
        </div>
      </div>
      <div>
        <div className="px-4 md:py-4 text-sm hidden md:block">
          {new Date(props.item.created).toLocaleString()}
          {props.item.body && (
            <div className="flex justify-end">
              <div>
                {ICONS.archive}
              </div>
            </div>
          )}
          {props.item.openUrl && (
            <div className="flex justify-end">
              <div>
                {ICONS.browser}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
