import React from "react";
import { useHistory } from "react-router";

// Util
import { SIDEBAR_ITEMS } from "../components/data/menu-data";

// Images
import mynotifierLogo from "../public/images/my-notifier-logo-transparent.png";

// Context
import { ProjectContext } from "../context/Project/context";
import { NotificationContext } from "../context/Notification/context";
import { AuthContext } from "../context/Auth/context";
// Consts
import {
  SET_ACTIVE_PROJECT,
  SET_MOUNTED_HOME,
} from "../context/Project/constants";
// Actions
import { getNotificationsAjax } from "../context/Notification/actions";
import { RECEIVE_NOTI_SUCCESS } from "../context/Notification/constants";

const SideBar = (props) => {
  // Context
  const { projectDispatch, projectState } = React.useContext(ProjectContext);
  const { notificationDispatch, notificationState } =
    React.useContext(NotificationContext);
  const { authState } = React.useContext(AuthContext);

  // State

  // History
  const history = useHistory();

  // On Mount

  React.useEffect(() => {
    // console.log("has mounted", projectState.mountedHome);
    if (!projectState.mountedHome) {
      // console.log(
      //   `receiving here:notification-${authState.id}-${
      //     projectState.activeProject ? projectState.activeProject : "all"
      //   }`
      // );
      // Sockets
      // Listen on New
      authState.socket?.on(
        `notification-${authState.id}-${
          projectState.activeProject ? projectState.activeProject : "all"
        }`,
        async (data) => {
          // console.log("new data", data);
          // console.log("noti state", notificationState);

          await notificationDispatch({
            type: RECEIVE_NOTI_SUCCESS,
            payload: data.newNoti,
          });

          // console.log(
          //   "received",
          //   `notification-${authState.id}-${
          //     projectState.activeProject ? projectState.activeProject : "all"
          //   }`
          // );
        }
      );
      projectDispatch({ type: SET_MOUNTED_HOME, payload: true });
    }
  }, [
    authState.id,
    authState.socket,
    notificationDispatch,
    notificationState,
    projectDispatch,
    projectState.activeProject,
    projectState.mountedHome,
  ]);

  const navigateHandler = (slug) => {
    if (slug === history.location.pathname) return;
    history.push(slug);
  };

  const projectHandler = async (item) => {
    // console.log(item);
    // console.log(
    //   `not listening here:notification-${authState.id}-${
    //     projectState.activeProject ? projectState.activeProject : "all"
    //   }`
    // );
    // Disconnect
    await authState.socket.off(
      `notification-${authState.id}-${
        projectState.activeProject ? projectState.activeProject : "all"
      }`
    );

    // Change
    await projectDispatch({
      type: SET_ACTIVE_PROJECT,
      payload: item ? item.projectId : "",
    });
    // projectDispatch({ type: SET_MOUNTED_HOME, payload: false });
    getNotificationsAjax(notificationDispatch, item ? item._id : "");

    // console.log(
    //   `receiving here:notification-${authState.id}-${
    //     item ? item.projectId : "all"
    //   }`
    // );

    // Listen on New
    await authState.socket.on(
      `notification-${authState.id}-${item ? item.projectId : "all"}`,
      async (data) => {
        // console.log("new data", data);
        // console.log("noti state", notificationState);

        await notificationDispatch({
          type: RECEIVE_NOTI_SUCCESS,
          payload: data.newNoti,
        });

        // console.log(
        //   "received",
        //   `notification-${authState.id}-${
        //     projectState.activeProject ? projectState.activeProject : "all"
        //   }`
        // );
      }
    );
  };

  const sidebarItemsOutput = SIDEBAR_ITEMS.map((item) => {
    return (
      <li
        key={`sidebar-${item.title}-${Math.random()}`}
        className="relative px-6 py-3"
        onClick={() => navigateHandler(item.slug)}
      >
        {history.location.pathname === item.slug && (
          <span
            className="absolute inset-y-0 left-0 w-1 bg-blue-600 rounded-tr-lg rounded-br-lg"
            aria-hidden="true"
          ></span>
        )}

        <p className="cursor-pointer inline-flex items-center w-full text-sm font-semibold text-gray-800 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100">
          {item.icon}
          <span className="ml-4">{item.title}</span>
        </p>
      </li>
    );
  });

  const sidebarProjectsOutput = projectState.projects.map((item) => {
    return (
      <li
        key={`project-sidebar-${item.title}-${Math.random()}`}
        onClick={() => projectHandler(item)}
        className="relative px-6 py-3 cursor-pointer rounded m-2 hover:bg-blue-100 duration-100 ease-in transition-all"
        style={{
          backgroundColor:
            item.projectId === projectState.activeProject &&
            "rgba(30,144,255, .4)",
        }}
      >
        <p className="inline-flex items-center w-full text-sm font-semibold text-gray-800 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100">
          <span className="ml-4">{item.name}</span>
        </p>
      </li>
    );
  });

  return (
    <>
      <div className="hidden h-screen bg-gray-50 dark:bg-gray-900 md:flex">
        <aside className="z-20 w-64 overflow-y-auto bg-white dark:bg-gray-800 md:block flex-shrink-0 border-r">
          <div className="py-4 text-gray-500 dark:text-gray-400 ">
            <div className="flex">
              <img src={mynotifierLogo} className="h-8 w-8 ml-2" alt="logo" />
              <h2 className="ml-2 mt-1 text-lg font-bold text-gray-800 dark:text-gray-200">
                myNotifier
              </h2>
            </div>
            <ul className="mt-6">{sidebarItemsOutput}</ul>
            <hr className="mx-4 my-2" />

            <>
              <ul className="mt-2">
                <li
                  onClick={() => projectHandler("")}
                  className="relative px-6 py-3 cursor-pointer m-2 rounded"
                  style={{
                    backgroundColor:
                      !projectState.activeProject && "rgba(30,144,255, .4)",
                  }}
                >
                  <p className=" inline-flex items-center w-full text-sm font-semibold text-gray-800 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100">
                    <span className="ml-4">Show All</span>
                  </p>
                </li>

                {sidebarProjectsOutput}
              </ul>
              <div className="px-6 my-6">
                <button
                  onClick={() => history.push("/projects")}
                  className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                >
                  Create project
                  <span className="ml-2" aria-hidden="true">
                    +
                  </span>
                </button>
              </div>
            </>
          </div>
        </aside>
      </div>
    </>
  );
};

export default SideBar;
