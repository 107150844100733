import React from "react";

// Modules
import Switch from "react-switch";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Components
import SideBar from "../hoc/SideBar";
import MobileMenu from "../hoc/MobileMenu";
import LoadingOverlay from "../components/LoadingOverlay";
import ErrorModal from "../components/ErrorModal";
import LogoutModal from "../components/LogoutModal";
import GoPro from "../components/GoPro";
import CancelMembershipModal from "../components/CancelMembershipModal";

// Axios
import axios from "../axios_orders";

// Firebase
import { messaging } from "../init-fcm";

// Context
import { AuthContext } from "../context/Auth/context";
import { SET_USER } from "../context/Auth/constants";

const Account = (props) => {
  // Context
  const { authDispatch, authState } = React.useContext(AuthContext);
  const [copied, setCopied] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [apiKey, setApiKey] = React.useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const [logoutModal, setLogoutModal] = React.useState(false);
  const [showGoProModal, setShowGoProModal] = React.useState(false);
  const [cancelMembership, setCancelMembership] = React.useState(false);

  const copyHandler = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 10000);
  };

  const getApiKey = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/users/api-key");
      // console.log(res);
      setApiKey(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      // alert(err.response.data.error);
      setLoading(false);
    }
  };

  const closeModalHandler = () => {
    setIsOpen(false);
    setError("");
  };

  const manageMembershipHandler = async () => {
    // alert('manage')
    if (loading) return;
    window.location.href = 'https://billing.stripe.com/p/login/14kaI9fox7fAc6c5kk';
    // sendUpdateToSlack('Get Started Buy Clicked 💰')
    // try {
    //   setLoading(true);
    //   const res = await axios.get("/payment/manageaccount");
    //   setLoading(false);
    //   window.location.href = res.data.url;
    // } catch (err) {
    //   setLoading(false);
    //   alert("Something went wrong. Contact jonas.mynotifier@gmail.com if it keeps happening.");
    //   console.log(err);
    // }
  };

  const unlinkHandler = async () => {
    if (!authState.desktopNotiKey) {
      setError("You are not linked to any desktop devices.");
      setIsOpen(true);
      return;
    }
    try {
      setLoading(true);
      await axios.post("/users/desktopnoti", {
        key: "",
      });
      getUser();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getUser = async () => {
    const res = await axios.get("/users/me");
    authDispatch({ type: SET_USER, payload: res.data.user });
  };

  const allowDesktopNotifications = async () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      alert("This browser does not support desktop notification");
      return;
    }
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
      // alert("safari not supported.");
      return;
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setError(
        "This is a mobile device. Log in on your dekstop and connect your device that way to receive notifications on your desktop. Download the app from the app store to get notifications on the go!"
      );
      setIsOpen(true);
      return;
    }

    if (authState.desktopNotiKey) {
      try {
        setLoading(true);
        await axios.post("/users/desktopnoti", {
          key: "",
        });
        getUser();
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }

      return;
    }

    // Ask Permission for Noti
    messaging
      .getToken({
        vapidKey:
          "BIscunbB12ynvyio1614ocTpYlIjtfTEx13owcjiqjplzC-dtDNXvaNsXwEhuu-1lI1e9khQHBdjiSS-n0Pudwk",
      })
      .then(async (currentToken) => {
        if (currentToken) {
          try {
            setLoading(true);
            await axios.post("/users/desktopnoti", {
              key: currentToken,
            });
            getUser();
            setLoading(false);
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        } else {
          // Show permission request UI
          // console.log(
          //   "No registration token available. Request permission to generate one."
          // );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  };

  const logoutHandler = () => {
    setLogoutModal(true);
  };

  return (
    <>
      {/* Mobile */}
      <MobileMenu />
      {/* Modal */}
      <ErrorModal
        modalIsOpen={modalIsOpen}
        closeModalHandler={closeModalHandler}
        error={error}
      />
      <LogoutModal
        modalIsOpen={logoutModal}
        closeModalHandler={() => setLogoutModal(false)}
      />

      {showGoProModal && (
        <GoPro
          modalIsOpen={showGoProModal}
          closeModalHandler={() => setShowGoProModal(false)}
        />
      )}
      {cancelMembership && (
        <CancelMembershipModal
          modalIsOpen={cancelMembership}
          closeModalHandler={() => setCancelMembership(false)}
        />
      )}

      {/* Desktop Navigation */}
      <div className="h-screen w-full flex bg-gray-100">
        {loading && <LoadingOverlay />}
        <SideBar />
        <div className="flex flex-col flex-1 w-full">
          {/* <divPageHeader /> */}

          <div className="h-full overflow-y-auto">
            <div className="container px-6 mx-auto grid">
              <h2 className="my-6 pt-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                Account
              </h2>

              <h3 className="my-6 mb-3 text-1xl font-semibold text-gray-700 dark:text-gray-200">
                Api Key
              </h3>

              {apiKey ? (
                <div className="font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 focus:outline-none focus:shadow-outline-blue">
                  <div className="flex justify-between">
                    <p>
                      Api Key: <i>{apiKey}</i>
                    </p>
                    <CopyToClipboard text={apiKey} onCopy={copyHandler}>
                      {/* {ICONS.copy} */}
                      <p className="cursor-pointer">
                        {copied ? "Copied!" : "< Copy to clipboard >"}
                      </p>
                    </CopyToClipboard>
                  </div>
                </div>
              ) : (
                <button
                  onClick={getApiKey}
                  className="font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                >
                  Reveal Api Key
                </button>
              )}
              <h3 className="my-6 mb-3 text-1xl font-semibold text-gray-700 dark:text-gray-200">
                Notifications
              </h3>

              {/* <div className="flex justify-between mb-4">
                <p className="text-lg">Notifications on this phone</p>
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={props.allowPhoneNotifications}
                  checked={props.userProfile?.phoneNotiToken}
                />
              </div> */}
              <div className="flex justify-between switch-container">
                <p className="text-lg">Notifications on this desktop</p>
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onChange={allowDesktopNotifications}
                  checked={authState.desktopNotiKey}
                />
              </div>

              <button
                onClick={unlinkHandler}
                className="font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue w-4/12"
              >
                Unlink
              </button>
              <h3 className="my-6 mb-4 text-1xl font-semibold text-gray-700 dark:text-gray-200">
                Profile
              </h3>
              <p className="mb-4">
                Email: <span className="font-bold">{authState.email}</span>
              </p>
              <p className="mb-4">
                Connected Phones:{" "}
                <span className="font-bold">
                  {authState.pushTokens?.length
                    ? authState.pushTokens?.length
                    : 0}
                </span>
              </p>
              <p className="mb-4">
                Account Type:{" "}
                <span className="font-bold">
                  {authState.status === 2 ? "PRO" : "Free"}
                </span>
              </p>
              {authState.status === 1 && (
                <div className="w-full mb-4">
                  <button
                    onClick={() => setShowGoProModal(true)}
                    className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                  >
                    Upgrade to Pro ⚡️
                  </button>
                </div>
              )}

              {/* {authState.status === 2 && (
                <div className="w-full mb-4">
                  <button
                    onClick={() => setCancelMembership(true)}
                    className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                  >
                    Cancel Membership
                  </button>
                </div>
              )} */}
              {authState.status === 2 && (
                <div className="w-full mb-4">
                  <button
                    onClick={manageMembershipHandler}
                    className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                  >
                    Manage Membership
                  </button>
                </div>
              )}
              <button
                onClick={logoutHandler}
                className="font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-gray-600 border border-transparent rounded-lg active:bg-gray-600 hover:bg-gray-700 focus:outline-none focus:shadow-outline-blue w-4/12"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
