import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { registerServiceWorker } from "./register-sw";
import "./styles/index.css";
import "./styles/tailwind.css";
import "semantic-ui-css/semantic.min.css";
// Providers
import AuthContextProvider from "./context/Auth/context";
import ProjectContextProvider from "./context/Project/context";
import NotificationContextProvider from "./context/Notification/context";

import { BrowserRouter } from "react-router-dom";

registerServiceWorker();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
        <NotificationContextProvider>
          <ProjectContextProvider>
            <App />
          </ProjectContextProvider>
        </NotificationContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
