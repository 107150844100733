import React, { useEffect } from "react";

// Modules
import { Route, Redirect, useLocation } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import socketClient from "socket.io-client";

// Pages
import Login from "./pages/Login";
import MainPage from "./pages/MainPage";

// Axios
import axios from "./axios_orders";

// Actions
import { getProjectsAjax } from "./context/Project/actions";
import { getNotificationsAjax } from "./context/Notification/actions";

// Toolkit
import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";

// Context
import { AuthContext } from "./context/Auth/context";
import { ProjectContext } from "./context/Project/context";
import { NotificationContext } from "./context/Notification/context";

// Constants
import { SET_SOCKET, SET_USER } from "./context/Auth/constants";

// Components
import LoadingOverlay from "./components/LoadingOverlay";
import CreateProject from "./components/CreateProject";
import Account from "./pages/Account";
import HowTo from "./pages/HowTo";
import Activate from "./pages/Activate";
import Analytics from "./pages/Analytics";

const socketUrl = process.env.REACT_APP_SOCKET_URL;

const App = () => {
  // Context
  const { authDispatch, authState } = React.useContext(AuthContext);
  const { projectDispatch, projectState } = React.useContext(ProjectContext);
  const { notificationDispatch } = React.useContext(NotificationContext);

  // State
  const [loading, setLoading] = React.useState(false);
  const [mounted, setMounted] = React.useState(false);

  // console.log(
  //   `notification-${authState.id}-${
  //     projectState.activeProject ? projectState.activeProject : "all"
  //   }`
  // );

  // Socket
  React.useEffect(() => {
    const initSocket = () => {
      const socket = socketClient(socketUrl);
      authDispatch({ type: SET_SOCKET, payload: socket });
    };
    if (!mounted) {
      initSocket();
      setMounted(true);
    }
  }, [authDispatch, authState.id, mounted, projectState.activeProject]);

  React.useEffect(() => {
    if (localStorage.mn4dsf8045463xd32 && !authState.id) {
      setLoading(true);
      axios.defaults.headers.common["Authorization"] =
        localStorage.mn4dsf8045463xd32;
      axios
        .get("/users/me")
        .then(async (res) => {
          authDispatch({ type: SET_USER, payload: res.data.user });
          await getProjectsAjax(projectDispatch);
          await getNotificationsAjax(notificationDispatch, "");

          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [authDispatch, authState.id, notificationDispatch, projectDispatch]);

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
  }, [location.pathname]); // triggered on route change

  // if (loading) return <LoadingOverlay />;

  if (!authState.id && !localStorage.mn4dsf8045463xd32) {
    return (
      <>
        <Route path="/login" component={Login} />
        <Route path="/activate" component={Activate} />
        <Route from="*" render={() => <Redirect to="/login" />} />
      </>
    );
  } else {
    return (
      <>
        {loading && <LoadingOverlay />}
        <div className="App">
          <div className="w-full min-h-screen">
            <Route exact path="/" component={() => <MainPage />} />
            <Route exact path="/activate" component={Activate} />
            <Route exact path="/projects" component={() => <CreateProject />} />
            <Route exact path="/account" component={() => <Account />} />
            <Route exact path="/how-to" component={() => <HowTo />} />
            <Route exact path="/analytics" component={() => <Analytics/>} />
            {/* <Route path="*" render={() => <Redirect to="/" />} /> */}
          </div>
        </div>
        <ToastContainer position="bottom-right" transition={Slide} />
      </>
    );
  }
};
export default App;
