import React from "react";
import axios from "../axios_orders";

// Components
// import DashboardCards from "./DashboardCards";
import NotificationItem from "./NotificationItem";
import ProgressBar from "./ProgressBar";
import NotificationModal from "./NotificationModal";

// Context
import { NotificationContext } from "../context/Notification/context";
import { AuthContext } from "../context/Auth/context";
import { ICONS } from "../util/icons";
import ConfirmModal from "./ConfirmModal";
import LoadingSpinner from "./LoadingSpinner";

// Actions
import { getNotificationsAjax } from "../context/Notification/actions";


const DashboardContent = (props) => {
  // Context
  const { notificationState, notificationDispatch } = React.useContext(NotificationContext);
  const { authState } = React.useContext(AuthContext);

  // State
  const [activeNoti, setActiveNoti] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const notifications = notificationState.notifications.map((item, index) => {
    return <NotificationItem setActiveNoti={setActiveNoti} item={item} key={`${index}-${item.name}`} />;
  });

  const confirmHandler = async () => {
    try {
      setLoading(true);
     await axios.delete("/notification/delete");
      setShowDelete(false);
      await getNotificationsAjax(notificationDispatch, "");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

  }

  // Content
  let content =
    <>
      <div className="container px-6 mx-auto grid">
        <div className="flex justify-between mb-4 mt-8" >
          <h2 className="my-6 pt-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
            Dashboard
          </h2>
          {
            authState.status !== 1 ?
              < div className="mt-8 cursor-pointer hover:text-gray-400" onClick={() => setShowDelete(true)}>
                {ICONS.delete}
              </div> : <div />
          }
        </div>
        {authState.status === 1 && (
          <ProgressBar
            notificationsList={notificationState.notifications}
            setShowGoProModal={props.setShowGoProModal}
            authState={authState}
          />
        )}

        {/* <DashboardCards notificationsList={props.notificationsList} /> */}

        {/* <!-- New Table --> */}
        <div className="w-full overflow-hidden rounded-lg shadow-xs mb-10">
          <div className="w-full overflow-x-auto">
            <div className="w-full whitespace-no-wrap">
              <div className="">
                <div className="flex  text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                  <p className="px-4 py-3">Notifications</p>
                  {/* <p className="px-4 py-3">Type</p>
          <p className="px-4 py-3">Date</p> */}
                </div>
              </div>
              <div className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                {notifications}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

    if(loading) {
      content = <div className="mt-16"><LoadingSpinner /></div>
    }

  return (
    <>
      {/* Modals */}
      <NotificationModal
        activeNoti={activeNoti}
        modalIsOpen={activeNoti ? true : false}
        closeModalHandler={() => setActiveNoti(null)}
      />
      <ConfirmModal
        modalIsOpen={showDelete}
        closeModalHandler={() => setShowDelete(false)}
        confirmHandler={() => confirmHandler()}
        confirmText="Yes. Delete all"
        bodyText="You're about to wipe all of your previous notifications.
        If you only want to delete some notifications, check out the docs in the 'How To' menu tab.
        Do you want to proceed?"
      />

      <main className="h-full overflow-y-auto">
{content}
      </main >
    </>
  );
};

export default DashboardContent;
