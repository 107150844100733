import React, { createContext, useReducer } from 'react';
import { notificationReducer, initialState } from './notificationReducer';

export const NotificationContext = createContext();

const NotificationContextProvider = (props) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  return (
    <NotificationContext.Provider value={{ notificationState: state, notificationDispatch: dispatch }}>
      {props.children}
    </NotificationContext.Provider>
  );
}
 
export default NotificationContextProvider;