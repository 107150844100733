import firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
  messagingSenderId: "570978044211",
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
});

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(
    "BIscunbB12ynvyio1614ocTpYlIjtfTEx13owcjiqjplzC-dtDNXvaNsXwEhuu-1lI1e9khQHBdjiSS-n0Pudwk"
  );
}

export { messaging };
