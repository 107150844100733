import React from "react";

// Components
import GoPro from "./GoPro";
import SideBar from "../hoc/SideBar";
import MobileMenu from "../hoc/MobileMenu";
import LoadingOverlay from "./LoadingOverlay";

// Modules
import { toast } from "react-toastify";

// Axios
import axios from "../axios_orders";

// Context
import { ProjectContext } from "../context/Project/context";
import { AuthContext } from "../context/Auth/context";

// Actions
import { getProjectsAjax } from "../context/Project/actions";

const CreateProject = (props) => {
  // Context
  const { projectDispatch, projectState } = React.useContext(ProjectContext);
  const { authState } = React.useContext(AuthContext);

  // State
  const [showGoProModal, setShowGoProModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [projectName, setProjectName] = React.useState("");

  const createProjectHandler = async () => {

    // const date1 = new Date();
    // const date2 = new Date(authState.trialEnd);
    // let trial = false;

    // if (date1 > date2) {
    //   trial = true;
    // }

    // Only if Pro
    if (authState.status === 1) {
      setShowGoProModal(true);
      return;
    }

    if (!projectName) return;

    try {
      setLoading(true);
      await axios.post("/project/create", {
        name: projectName,
      });
      await getProjectsAjax(projectDispatch);
      toast.success("New project added");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }

    // await firestore.collection("projects").add({
    //   name: projectName,
    //   projectId: generateProjectId(),
    //   status: 1,
    //   users: [user.uid],
    //   createdAt: new Date().toISOString(),
    // });

    setProjectName("");
    // console.log("Added document with ID: ", res.id);
  };

  const projectsOutput = projectState.projects.map((item) => {
    return (
      <tr key={Math.random() * 10} className="text-gray-700 dark:text-gray-400">
        <td className="px-4 py-3">
          <div className="flex items-center text-sm">
            {/* <!-- Avatar with inset shadow --> */}
            {/* <div className="relative hidden w-8 h-8 mr-3 rounded-full md:block">
              <img
                className="object-cover w-full h-full rounded-full"
                src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&ixid=eyJhcHBfaWQiOjE3Nzg0fQ"
                alt=""
                loading="lazy"
              />
              <div
                className="absolute inset-0 rounded-full shadow-inner"
                aria-hidden="true"
              ></div>
            </div> */}
            <div>
              <p className="font-semibold">{item.name}</p>
              {/* <p className="text-xs text-gray-600 dark:text-gray-400">
            10x Developer
          </p> */}
            </div>
          </div>
        </td>
        <td className="px-4 py-3 text-sm">{item.projectId}</td>
        <td className="px-4 py-3 text-xs">
          <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full dark:bg-green-700 dark:text-green-100">
            Active
          </span>
        </td>
        <td className="px-4 py-3 text-sm">
          {new Date(item.created).toLocaleDateString()}
        </td>
      </tr>
    );
  });

  return (
    <>
      {showGoProModal && (
        <GoPro
          modalIsOpen={showGoProModal}
          closeModalHandler={() => setShowGoProModal(false)}
        />
      )}
      {/* Mobile */}
      <MobileMenu />
      {/* Desktop Navigation */}
      <div className="h-screen w-full flex bg-gray-100">
        {loading && <LoadingOverlay />}
        <SideBar />
        <div className="flex flex-col flex-1 w-full">
          {/* <MainPageHeader /> */}
          <div className="h-full overflow-y-auto">
            <div className="container px-6 mx-auto grid">
              <h2 className="my-6 pt-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                Projects
              </h2>

              {/* <!-- New Table --> */}
              <div className="w-full overflow-hidden rounded-lg shadow-xs mb-10">
                <div className="w-full overflow-x-auto">
                  <table className="w-full whitespace-no-wrap">
                    <thead>
                      <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                        <th className="px-4 py-3">Name</th>
                        <th className="px-4 py-3">Project ID</th>
                        <th className="px-4 py-3">Status</th>
                        <th className="px-4 py-3">Created</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                      {projectsOutput}
                    </tbody>
                  </table>
                </div>
              </div>

              <h3 className="mb-2 font-semibold text-gray-700 dark:text-gray-200">
                Create a new project
              </h3>
              <div className="px-4 py-3 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">
                <label className="block text-sm">
                  <span className="text-gray-700 dark:text-gray-400">Name</span>
                  <input
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-blue-400 focus:outline-none focus:shadow-outline-blue dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="myNotifier"
                  />
                </label>

                <button
                  onClick={createProjectHandler}
                  className="font-bold mt-4 px-4 py-2 text-sm leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                >
                  Create Project
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProject;
