import React from "react";

// Modules
import ReactModal from "react-modal";

// Components
import LoadingSpinner from "./LoadingSpinner";

// Axios
import axios from "../axios_orders";


const CancelMembershipModal = (props) => {
  // Contexts

  // State
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const submitHandler = async () => {
    try {
      setLoading(true);
       await axios.post("/payment/cancelsubscription");
      setSuccess(true);
      // setLoading(false);
    } catch (err) {
      console.log(err);
      alert("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const closeModalHandler = () => {
    setSuccess(false);
    props.closeModalHandler();
  };

  // Content
  let content = (
    <>
      <h2 className="font-bold text-xl mb-4">Sad to see you go!</h2>
      <div className="w-full">
        <p className="mt-4 text-lg">
          We’d love to learn the reason behind your interest in cancelling so we
          can better serve you and all of the other customers moving forward.
          Write me an email <a href="mailto:jonas.mynotifier@gmail.com">here</a>
          . I'd love to hear from you!
        </p>

        <button
          onClick={submitHandler}
          className="w-full px-4 py-2 mx-auto mt-6 text-base font-medium text-white transition duration-500 ease-in-out transform bg-blue-600 border-blue-600 rounded-md items-centerw-full focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:bg-blue-700 "
        >
          Cancel my membership{" "}
        </button>
        {/* <div className="w-full bg-blue pt-8 text-center">
          <p className="mt-2 text-sm">
            <b>myNotifier</b> is run, developed, and designed by a{" "}
            <b>solo developer</b>, so any form of help towards further growth
            would be greatly appreciated.
          </p>
          <p className="mt-2 text-sm">
            That could be either <b>financial</b> support or valuable{" "}
            <b>feedback</b> on how to improve <b>myNotifier</b>.
          </p>
        </div> */}
      </div>
    </>
  );

  if (loading) {
    content = <LoadingSpinner />;
  }

  if (success) {
    content = (
      <>
        <h2 className="font-bold text-xl mb-4">Sad to see you go!</h2>
        <div className="w-full">
          <p className="mt-4 text-lg">
            As per your request, your subscription has been canceled. The good
            news is that your account will be active until the end of the month and you can
            still access all the features of myNotifier in the meantime. Hope to see you back one day!
          </p>
          <button
            onClick={closeModalHandler}
            className="w-full px-4 py-2 mx-auto mt-6 text-base font-medium text-white transition duration-500 ease-in-out transform bg-blue-600 border-blue-600 rounded-md items-centerw-full focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:bg-blue-700 "
          >
            OK
          </button>
        </div>
      </>
    );
  }

  return (
    <div>
      {/* Modal */}
      <ReactModal
        isOpen={props.modalIsOpen}
        closeTimeoutMS={350}
        contentLabel="PRO Feature"
        className="modal w-full h-full sm:h-auto sm:w-2/3 md:w-1/2 xl:w-1/3 rounded shadow"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setSuccess(false);
          props.closeModalHandler();
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col p-10 bg-white rounded">{content}</div>
      </ReactModal>
    </div>
  );
};

export default CancelMembershipModal;
