import React from "react";

// Components
import SideBar from "../hoc/SideBar";
import MobileMenu from "../hoc/MobileMenu";
import AppButtons from "../components/AppButtons";

// Axios
import axios from "../axios_orders";

// Modules
import { CopyBlock, a11yDark } from "react-code-blocks";
import { sample, TopBar } from "../components/data";
import LoadingSpinner from "../components/LoadingSpinner";

const HowTo = (props) => {
  const [language, changeLanguage] = React.useState("JavaScript");
  const [languageDemo, changeDemo] = React.useState(sample["JavaScript"]);
  const [codeData, setCodeData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getApiKey = async () => {
      setLoading(true);
      try {
        const res = await axios.get("/users/api-key");
        // console.log(res);
        // setApiKey(res.data);
        setCodeData({
          JavaScript: `import axios from 'axios'
          
            axios.post('https://api.mynotifier.app', {
              apiKey: '${res.data}', // This is your own private key
              message: "New payment!", // Could be anything
              description: "A payment off $99 has been made", // Optional
              body: "", // Optional
              type: "success", // info, error, warning or success
              project: "" // Optional. Project ids can be found in project tab <-
            })`,
          Ruby: `url = URI.parse("https://api.mynotifier.app")
          req = Net::HTTP::Post.new(url.path)
          req.set_form_data({
            :apiKey => '${res.data}', # This is your own private key
            :message => "Someone is at the door", # Could be anything
            :description => "At 22:00 someone triggered the camera by the frontdoor", # Optional
            :body => "", # Optional
            :type => "info", # info, error, warning or success
            :project => "" # Optional. Project ids can be found in project tab <-
          })`,
          Python: `
            requests.post('https://api.mynotifier.app', {
              "apiKey": '${res.data}', # This is your own private key
              "message": "Someone is at the door", # Could be anything
              "description": "At 22:00 someone triggered the camera by the frontdoor", # Optional
              "body": "", # Optional
              "type": "info", # info, error, warning or success
              "project": "" # Optional. Project ids can be found in project tab <-
            })
            `,
          Go: `func main() {
              http.PostForm("https://api.mynotifier.app", url.Values{
                  "apiKey": {'${res.data}'}, // This is your own private key
                  "message": {"Server error"}, // Could be anything
                  "description": {"Critical Server Error!"}, // Optional
                  "body": {""}, // Optional
                  "type": {"error"}, // info, error, warning or success
                  "project": {""} // Optional. Project ids can be found in project tab <-
              })
          }`,
        });

        changeDemo(`import axios from 'axios'
          
        axios.post('https://api.mynotifier.app', {
          apiKey: '${res.data}', // This is your own private key
          message: "New payment!", // Could be anything
          description: "A payment off $99 has been made", // Optional
          body: "", // Optional
          type: "success", // info, error, warning or success
          project: "" // Optional. Project ids can be found in project tab <-
        })`);
        setLoading(false);
      } catch (err) {
        console.log(err);
        // alert(err.response.data.error);
        setLoading(false);
      }
    };

    getApiKey();
  }, []);

  if (loading) return <LoadingSpinner />;

  return (
    <>
      {/* Mobile */}
      <MobileMenu />
      {/* Desktop Navigation */}
      <div className="h-screen w-full flex bg-gray-100">
        <SideBar />
        <div className="flex flex-col flex-1 w-full">
          {/* <divPageHeader /> */}

          <main className="h-full overflow-y-auto">
            <div className="container px-6 mx-auto grid">
              <h2 className="my-6 pt-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                How To
              </h2>
              <div className="">
                <div>
                  <TopBar
                    language={{
                      value: language,
                      onChange: (e) => {
                        changeDemo(codeData[e.target.value]);
                        return changeLanguage(e.target.value);
                      },
                      options: Object.keys(sample).map((lang) => (
                        <option key={lang} value={lang}>
                          {lang}
                        </option>
                      )),
                    }}
                  />
                  <CopyBlock
                    language={language}
                    text={languageDemo}
                    showLineNumbers={true}
                    theme={a11yDark}
                    wrapLines={true}
                    codeBlock
                    className="w-full"
                  />
                </div>

                <p className="mt-4">
                  Unsure on how to set it up? Here's a guide and Docs!
                </p>

                <div className="flex">

                  <a
                    href="https://medium.com/mynotifier/how-to-send-push-notifications-to-yourself-7d96236e0777"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      // onClick={props.createProjectHandler}
                      className="font-bold mr-4 px-4 py-2 text-sm leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                    >
                      Go to guide!
                    </button>
                  </a>
                  <a
                    href="https://mynotifier.stoplight.io/docs/mynotifier/78784d80b519b-send-notification"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      // onClick={props.createProjectHandler}
                      className="font-bold px-4 py-2 text-sm leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
                    >
                      Go to Docs!
                    </button>
                  </a>
                </div>
                <AppButtons />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default HowTo;
