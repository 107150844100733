import { SET_USER, SET_SOCKET, CLEAR_USER } from "./constants";

export const initialState = {
  email: "",
  id: "",
  socket: null
};

export const authReducer = (state, action) => {
  switch (action.type) {

    case SET_USER:
      return {
        ...state,
        ...action.payload
      }

    case CLEAR_USER:
      return {
        ...state,
        email: "",
        id: "",
      }

    case SET_SOCKET:
      return {
        ...state,
        socket: action.payload
      }

    default:
      return state;
  }
};
