import axios from "../../axios_orders";

import {
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
} from "./constants";

export const getNotificationsAjax = async (dispatch, projectId) => {
  try {
    dispatch({ type: GET_NOTIFICATIONS_START });
    const data = { project: projectId };
    const res = await axios.post("/notification/get", data);
    dispatch({ type: GET_NOTIFICATIONS_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: GET_NOTIFICATIONS_FAILURE });
  }
};
