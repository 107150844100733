import {
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_FAILURE,
  RECEIVE_NOTI_SUCCESS,
} from "./constants";

export const initialState = {
  notifications: [],
  loadingNotifications: false,
};

export const notificationReducer = (state, action) => {
  // Variable
  const newNotis = [...state.notifications];
  // Switch
  switch (action.type) {
    case GET_NOTIFICATIONS_START:
      return {
        ...state,
        loadingNotifications: true,
      };

    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        loadingNotifications: false,
      };

    case GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loadingNotifications: false,
      };

    case RECEIVE_NOTI_SUCCESS:
      return {
        ...state,
        notifications: [action.payload].concat(newNotis),
      };

    default:
      return state;
  }
};
