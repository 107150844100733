import React from "react";

import LoadingSpinner from "./LoadingSpinner";

const LoadingOverlay = () => {
  return (
    <>
      <div className="bg-gray-200 flex items-center justify-center h-screen fixed w-screen opacity-50 z-50">
        {/* <div className="modal pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center"> */}
          <LoadingSpinner />
        {/* </div> */}
      </div>
    </>
  );
};

export default LoadingOverlay;
