import { ICONS } from "../../util/icons";

export const SIDEBAR_ITEMS = [
  {
    title: "Dashboard",
    //   slug: SIDEBAR_VARIABLES.dashboard,
    slug: "/",
    icon: ICONS.home,
  },
  {
    title: "Analytics",
    //   slug: SIDEBAR_VARIABLES.dashboard,
    slug: "/analytics",
    icon: ICONS.analytics,
  },
  {
    title: "Projects",
    // slug: SIDEBAR_VARIABLES.projects,
    slug: "/projects",
    icon: ICONS.layer,
  },
  {
    title: "Account",
    //   slug: SIDEBAR_VARIABLES.accountInfo,
    slug: "/account",
    icon: ICONS.cog,
  },
  {
    title: "How To",
    // slug: SIDEBAR_VARIABLES.howTo,
    slug: "/how-to",
    icon: ICONS.information,
  },
];
