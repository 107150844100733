import React from "react";

// Modules
import ReactModal from "react-modal";

import { ProjectContext } from "../context/Project/context";

import { TYPES } from "./NotificationItem";

// Modal
ReactModal.setAppElement("#root");

function jsUcfirst(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const NotificationModal = (props) => {
  // History

  const { projectState } = React.useContext(ProjectContext);

  let statusColor = "blue";

  switch (props.activeNoti?.type) {
    case TYPES.info:
      statusColor = "blue";
      break;

    case TYPES.warning:
      statusColor = "yellow";
      break;

    case TYPES.error:
      statusColor = "red";
      break;

    case TYPES.success:
      statusColor = "green";
      break;

    default:
      break;
  }

  let project;

  if (props.activeNoti?.project) {
    project = projectState.projects.find(
      (x) => x._id === props.activeNoti.project
    );
  }

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      closeTimeoutMS={350}
      contentLabel="Error Modal"
      className="modal w-full h-full sm:h-auto sm:w-2/3 md:w-1/2 xl:w-1/3 rounded shadow"
      overlayClassName="modal-overlay"
      onRequestClose={() => {
        props.closeModalHandler();
      }}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className="flex flex-col p-10 bg-white rounded">
        {new Date(props.activeNoti?.created).toLocaleString()}
        <div className="py-3 text-xs hidden md:block">
          <div
            className={`px-4 py-1 font-semibold leading-tight rounded-full text-${statusColor}-700 bg-${statusColor}-100 w-24 text-center `}
          >
            {jsUcfirst(props.activeNoti?.type)}
          </div>
        </div>

        <p className="font-bold text-lg">Message</p>
        <p className="font-normal text-lg">{props.activeNoti?.message}</p>
        {project && (
          <>
            <p className="font-bold text-lg">Project</p>
            <p className="font-normal text-lg">{project.name}</p>
          </>
        )}
        {props.activeNoti?.description && (
          <div className="overflow-y-scroll mb-8" style={{ maxHeight: 300 }}>
            <p className="font-bold text-lg">Description</p>
            <p className="font-normal text-lg">
              {props.activeNoti?.description}
            </p>
          </div>
        )}
        {props.activeNoti?.openUrl && (
          <div className="mb-8" style={{ maxHeight: 300 }}>
            <p className="font-bold text-lg">URL</p>
            <a href={props.activeNoti?.openUrl} target="_blank" rel="noreferrer">
              <p className="font-normal text-lg">{props.activeNoti?.openUrl}</p>
            </a>
          </div>
        )}
        {props.activeNoti?.body && (
          <div className="overflow-y-scroll mb-8" style={{ maxHeight: 300 }}>
            <p className="font-bold text-lg">Body</p>
            <p className="font-normal text-lg">{props.activeNoti?.body}</p>
          </div>
        )}

        {/* Always Close Button */}
        <div className="flex justify-between">
          <button
            onClick={props.closeModalHandler}
            className="w-1/2 mr-4 font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-gray-600 border border-transparent rounded-lg active:bg-gray-600 hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray"
          >
            Back
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default NotificationModal;
