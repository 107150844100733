import React from "react";

// Images
import mynotifierLogo from "../public/images/my-notifier-logo-transparent.png";

import { useHistory } from "react-router";

// Context
import { ProjectContext } from "../context/Project/context";
import { NotificationContext } from "../context/Notification/context";
import { AuthContext } from "../context/Auth/context";

// Actions
import { getNotificationsAjax } from "../context/Notification/actions";
import { RECEIVE_NOTI_SUCCESS } from "../context/Notification/constants";

// Util
import { SIDEBAR_ITEMS } from "../components/data/menu-data";
import { SET_ACTIVE_PROJECT } from "../context/Project/constants";

const MobileMenu = (props) => {
  // Context
  const { projectDispatch, projectState } = React.useContext(ProjectContext);
  const { notificationDispatch } = React.useContext(NotificationContext);
  const { authState } = React.useContext(AuthContext);

  const [openMenu, setOpenMenu] = React.useState(false);

  // History
  const history = useHistory();

  // Functions
  const itemClickHandler = (slug) => {
    // props.setActivePage(slug);
    if (slug === history.location.pathname) return;
    history.push(slug);
    setOpenMenu(false);
  };

  const projectHandler = async (item) => {
    // console.log(item);
    // console.log(
    //   `not listening here:notification-${authState.id}-${
    //     projectState.activeProject ? projectState.activeProject : "all"
    //   }`
    // );
    // Disconnect
    await authState.socket.off(
      `notification-${authState.id}-${
        projectState.activeProject ? projectState.activeProject : "all"
      }`
    );

    // Change
    await projectDispatch({
      type: SET_ACTIVE_PROJECT,
      payload: item ? item.projectId : "",
    });
    // projectDispatch({ type: SET_MOUNTED_HOME, payload: false });
    getNotificationsAjax(notificationDispatch, item ? item._id : "");

    // console.log(
    //   `receiving here:notification-${authState.id}-${
    //     item ? item.projectId : "all"
    //   }`
    // );

    // Listen on New
    await authState.socket.on(
      `notification-${authState.id}-${item ? item.projectId : "all"}`,
      async (data) => {
        // console.log("new data", data);
        // console.log("noti state", notificationState);

        await notificationDispatch({
          type: RECEIVE_NOTI_SUCCESS,
          payload: data.newNoti,
        });

        // console.log(
        //   "received",
        //   `notification-${authState.id}-${
        //     projectState.activeProject ? projectState.activeProject : "all"
        //   }`
        // );
      }
    );
  };

  // Output
  const sidebarItemsOutput = SIDEBAR_ITEMS.map((item) => {
    return (
      <li
        onClick={() => itemClickHandler(item.slug)}
        className={`text-gray block px-3 py-2 rounded-md text-base font-medium ${
          item.slug === history.location.pathname && "bg-blue-700 text-white"
        }`}
      >
        {item.title}
      </li>
    );
  });

  const sidebarProjectsOutput = projectState.projects.map((item) => {
    return (
      <li
        // onClick={() => props.setActiveProject(item.projectId)}
        onClick={() => {
          projectHandler(item);
        }}
        className="relative px-6 py-3 cursor-pointer rounded m-2"
        style={{
          backgroundColor:
            item.projectId === projectState.activeProject &&
            "rgba(30,144,255, .4)",
        }}
      >
        <p className="inline-flex items-center w-full text-sm font-semibold text-gray-800 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100">
          <span>{item.name}</span>
        </p>
      </li>
    );
  });
  return (
    <div className="w-full md:hidden top-0 border-b">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <img src={mynotifierLogo} className="h-8 w-8 ml-2" alt="logo" />
              <img
                className="hidden lg:block h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                alt="Workflow"
              />
            </div>
          </div>
        </div>
      </div>

      {openMenu && (
        <div>
          <div className="px-2 pt-2 pb-3 space-y-1">{sidebarItemsOutput}</div>

          <h2 className="ml-4 text-1xl font-semibold text-gray-700 dark:text-gray-200">
            Active Project
          </h2>
          <ul>
            <li
              onClick={() => {
                projectHandler("");
              }}
              className="relative px-6 py-3 cursor-pointer m-2 rounded"
              style={{
                backgroundColor:
                  !projectState.activeProject && "rgba(30,144,255, .4)",
              }}
            >
              <p className=" inline-flex items-center w-full text-sm font-semibold text-gray-800 transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 dark:text-gray-100">
                <span>Show All</span>
              </p>
            </li>
            {sidebarProjectsOutput}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
