import React from "react";

// Modules
import ReactModal from "react-modal";

// Modal
ReactModal.setAppElement("#root");

const ConfirmModal = (props) => {


  const confirmHandler = async () => {
    props.confirmHandler();
  };

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      closeTimeoutMS={350}
      contentLabel="Error Modal"
      className="modal w-full h-full sm:h-auto sm:w-2/3 md:w-1/2 xl:w-1/3 rounded shadow"
      overlayClassName="modal-overlay"
      onRequestClose={() => {
        props.closeModalHandler();
      }}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
    >
      <div className="flex flex-col p-10 bg-white rounded">
        <p className="font-normal text-lg">
          {props.bodyText}
        </p>

        {/* Always Close Button */}
        <div className="flex justify-between">
          <button
            onClick={props.closeModalHandler}
            className="w-1/2 mr-4 font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-gray-600 border border-transparent rounded-lg active:bg-gray-600 hover:bg-gray-700 focus:outline-none focus:shadow-outline-gray"
          >
            Close
          </button>
          <button
            onClick={confirmHandler}
            className="w-1/2 font-bold mt-4 px-4 py-2 text-lg leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-gray"
          >
            {props.confirmText}
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ConfirmModal;
