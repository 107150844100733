import React, { createContext, useReducer } from 'react';
import { projectReducer, initialState } from './projectReducer';

export const ProjectContext = createContext();

const ProjectContextProvider = (props) => {
  const [state, dispatch] = useReducer(projectReducer, initialState);

  return (
    <ProjectContext.Provider value={{ projectState: state, projectDispatch: dispatch }}>
      {props.children}
    </ProjectContext.Provider>
  );
}
 
export default ProjectContextProvider;