import {
  GET_PROJECTS_SUCCESS,
  SET_ACTIVE_PROJECT,
  SET_MOUNTED_HOME,
} from "./constants";

export const initialState = {
  projects: [],
  activeProject: "",
  mountedHome: false,
};

export const projectReducer = (state, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    case SET_ACTIVE_PROJECT:
      return {
        ...state,
        activeProject: action.payload,
      };

    case SET_MOUNTED_HOME:
      return {
        ...state,
        mountedHome: action.payload,
      };

    default:
      return state;
  }
};
