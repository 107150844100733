import React from "react";

// Modules
import { Progress } from "semantic-ui-react";

const ProgressBar = (props) => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  // console.log

  let notifications = props.notificationsList.map((item) => {
    const notiDate = new Date(item.created);
    if (notiDate > firstDay && notiDate < lastDay) {
      return item;
    } else {
      return null;
    }
  });



  notifications = notifications.filter(Boolean);

  // console.log(notifications)
  // const differenceDays = () => {
  //   var date1 = new Date();
  //   var date2 = new Date(props.authState.trialEnd);

  //   if (date1 > date2) {
  //     return -1;
  //   }

  //   // To calculate the time difference of two dates
  //   var Difference_In_Time = date2.getTime() - date1.getTime();

  //   // To calculate the no. of days between two dates
  //   var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  //   return Math.ceil(Difference_In_Days);
  // }

  // const generateText = () => {
  //   const diff = differenceDays();

  //   if(diff < 0) {
  //     return <span>Your trial has ended. </span>
  //   }
    
  //   if(diff === 0) {
  //     return <span>Your trial will end <span className="font-bold">today</span>. </span>
  //   }
  //   return <span> Your trial will end in <span className="font-bold">{diff}</span> {diff === 1 ? 'day. ' : 'days. ' }</span>
  // }

  return (
    <div>
      <div className="flex ">
        {/* <p className="text-sm mt-4" style={{marginTop: 6}}>
         {generateText()} Get
          myNotifier Pro and never worry about running out of requests! ⚡️
        </p> */}
        <div className="mt-1 w-3/4">
          <Progress
            value={notifications.length}
            total={25}
            progress={notifications.length > 1 ? "ratio" : "value"}
            color="blue"
          />
        </div>
        <div className="ml-4 w-1/4 mb-4">
          <button
            onClick={() => props.setShowGoProModal(true)}
            className="flex items-center justify-between px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
          >
            Go Pro ⚡️
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
