import React from 'react'

// Components
import GoProModal from "../components/GoProModal";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE_KEY);

const GoPro = props => {
    return (
        <Elements stripe={stripePromise}>
          <GoProModal
            modalIsOpen={props.modalIsOpen}
            closeModalHandler={() => props.closeModalHandler(false)}
          />
        </Elements>
    )
}


export default GoPro;