import React from "react";

import { useHistory } from "react-router";

import axios from "../axios_orders";

import { SET_USER } from "../context/Auth/constants";

// Actions
import { getProjectsAjax } from "../context/Project/actions";
import { getNotificationsAjax } from "../context/Notification/actions";

// Context
import { AuthContext } from "../context/Auth/context";
import { ProjectContext } from "../context/Project/context";
import { NotificationContext } from "../context/Notification/context";
import LoadingSpinner from "../components/LoadingSpinner";

const Activate = () => {
  // State
  const [loading, setLoading] = React.useState(true);
  const [mounted, setMounted] = React.useState(false);

  const { authDispatch } = React.useContext(AuthContext);
  const { projectDispatch } = React.useContext(ProjectContext);
  const { notificationDispatch } = React.useContext(NotificationContext);

  const history = useHistory();

  React.useEffect(() => {
    const activate = async () => {
      axios.defaults.headers.common["Authorization"] =
        localStorage.mn4dsf8045463xd32;
      const res = await axios.get("/users/activate");

      if (res.status !== 200) {
        history.push("/");
        return;
      }

      axios
        .get("/users/me")
        .then(async (res) => {
          authDispatch({ type: SET_USER, payload: res.data.user });
          await getProjectsAjax(projectDispatch);
          await getNotificationsAjax(notificationDispatch, "");

          setLoading(false);
          history.push("/");
        })
        .catch((err) => {
          setLoading(false);
          history.push("/");
        });

      history.push("/");
    };

    if (!mounted) {
      activate();
      setMounted(true);
    }
  }, [
    authDispatch,
    history,
    mounted,
    notificationDispatch,
    projectDispatch,
    setLoading,
  ]);
  return <div className="mt-56">{loading && <LoadingSpinner />}</div>;
};

export default Activate;
