import React from "react";

// Modules
import { withRouter } from "react-router-dom";

import GoogleLogin from "react-google-login";

const ShowLogin = (props) => {
  return (
    <>
      {/*  Site header */}
      {/* <Header /> */}
      <main className="flex-grow">
        <section className="bg-gradient-to-b from-gray-100 to-white">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-24 md:pb-20">
              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-8">
                <h1 className="h1 font-bold text-4xl">
                  Welcome back.
                  <br />
                  Good to see you back.
                </h1>
              </div>
              <p className="font-semibold text-base text-green-600 text-center mb-6">
                {props.message}
              </p>

              {/* Form */}
              <div className="max-w-sm mx-auto">
                <form>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-800 text-sm font-medium mb-1"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        value={props.email}
                        onChange={(e) => props.inputHandler("email", e)}
                        id="email"
                        type="email"
                        className="form-input w-full text-gray-800"
                        placeholder="Enter your email address"
                        required
                      />
                    </div>
                    <p className="text-sm text-red-600 mt-2 px-1">
                      {props.errors.email}
                    </p>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-4">
                    <div className="w-full px-3">
                      <div className="flex justify-between">
                        <label
                          className="block text-gray-800 text-sm font-medium mb-1"
                          htmlFor="password"
                        >
                          Password
                        </label>
                        {/* <Link
                          to="reset-password"
                          className="text-sm font-medium text-blue-600 hover:underline"
                        >
                          Having trouble signing in?
                        </Link> */}
                      </div>
                      <input
                        value={props.password}
                        onChange={(e) => props.inputHandler("password", e)}
                        id="password"
                        type="password"
                        className="form-input w-full text-gray-800"
                        placeholder="Enter your password"
                        required
                      />
                    </div>
                    <p className="text-sm text-red-600 mt-2 px-1">
                      {props.errors.password}
                    </p>
                    <p className="text-sm text-red-600 mt-2 px-1">
                      {props.errors.general}
                    </p>
                    <p className="text-sm text-red-600 mt-2 px-1">
                      {props.errors.loginPassword}
                    </p>
                    <p className="text-sm text-red-600 mt-2">
                      {props.errors.loginEmail}
                    </p>
                  </div>
                  <div className="flex flex-wrap -mx-3 mt-6">
                    <div className="w-full px-3">
                      <button
                        className="btn p-3 rounded text-white bg-blue-600 hover:bg-blue-700 w-full"
                        onClick={props.loginHandler}
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                </form>
                <div className="flex items-center my-6">
                  <div
                    className="border-t border-gray-300 flex-grow mr-3"
                    aria-hidden="true"
                  ></div>
                  <div className="text-gray-600 italic">Or</div>
                  <div
                    className="border-t border-gray-300 flex-grow ml-3"
                    aria-hidden="true"
                  ></div>
                </div>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Log in with Google"
                  onSuccess={props.handleLoginGoogle}
                  onFailure={props.handleLoginGoogle}
                  cookiePolicy={"single_host_origin"}
                  className="w-full flex justify-center"
                />
                <div className="text-gray-600 text-center mt-6">
                  Don’t you have an account?{" "}
                  <span
                    onClick={() => props.setShowLogin(!props.showLogin)}
                    className="text-blue-600 hover:underline transition duration-150 ease-in-out cursor-pointer"
                  >
                    Sign up
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default withRouter(ShowLogin);
