import React from "react";

// Modules
import ReactModal from "react-modal";

// Components
import LoadingSpinner from "./LoadingSpinner";

// Axios
import axios from "../axios_orders";

// Context
import { AuthContext } from "../context/Auth/context";

// Images
import CheckMark from "../public/images/check-mark-circle-thin.png";

const GoProModal = (props) => {
  // Contexts
  const { authState } = React.useContext(AuthContext);

  // State
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const ajaxMN = () => {
    fetch("https://api.mynotifier.app", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: "3c8386d8-84c3-4780-b769-213a7ad121a2",
        message: "Stripe Checkout - Web",
        description: "🙌🙌🙌",
        type: "info",
        projectId: "",
      }),
    });
  }

  const goProHandler = async () => {
    ajaxMN();
    try {
      setLoading(true);
      const res = await axios.post("/payment/createsubscriptionlink", {email: authState.email});
      console.log(res);
      window.location.replace(res.data.url);
      // setLoading(false);
    }
    catch (err) {
      setLoading(false);
      console.log(err)
      alert('Something went wrong');
    }
  };

  const closeModalHandler = () => {
    setSuccess(false);
    props.closeModalHandler();
  };

  // Content
  let content = (
    <>
      <h2 className="font-bold text-xl mb-4">This is a PRO Feature!</h2>
      <div className="w-full items-center px-5 py-12 lg:px-20">
        <div className="flex flex-wrap items-center justify-center w-full gap-4">
          <div className="w-full">
            <div className="relative flex flex-col h-full p-8 transition duration-500 ease-in-out transform bg-white border rounded-lg shadow-xl">
              <h2 className="mb-4 text-sm font-medium tracking-widest text-black uppercase title-font">
                myNotifier Pro
              </h2>
              <p className="flex items-center mb-8 text-base font-medium leading-relaxed text-blueGray-700">
                {" "}
                Get full access to everything{" "}
              </p>
              <div className="flex">
                <h3 className="flex items-end text-3xl font-black leading-none text-black lg:text-4xl ">
                  $6
                </h3>
                <p className="mt-4">/mo</p>
              </div>
              <p className="flex items-center mt-8 mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white rounded-full bg-black">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Unlimited requests
              </p>
              <p className="flex items-center mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white rounded-full bg-black">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Unlimited projects
              </p>
              <p className="flex items-center mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white rounded-full bg-black">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Feature requests
              </p>
              <p className="flex items-center mb-2 text-base font-medium leading-relaxed text-blueGray-700">
                <span className="inline-flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 text-white rounded-full bg-black">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2.5"
                    className="w-3 h-3"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                Full support
              </p>
              <button
                onClick={goProHandler}
                className="w-full px-4 py-2 mx-auto mt-6 text-base font-medium text-white transition duration-500 ease-in-out transform bg-blue-600 border-blue-600 rounded-md items-centerw-full focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:bg-blue-700 "
              >
                Go Pro{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="w-full bg-blue pt-8 text-center">
          <p className="mt-2 text-sm">
            <b>myNotifier</b> is run, developed, and designed by a{" "}
            <b>solo developer</b>, so any form of help towards further growth
            would be greatly appreciated ⚡️
          </p>
          <p className="mt-2 text-sm">
            That could be either <b>financial</b> support or valuable{" "}
            <b>feedback</b> on how to improve <b>myNotifier</b> 💙
          </p>
        </div>
      </div>
    </>
  );


  if(loading) {
    content = <LoadingSpinner />
  }

  if (success) {
    content = (
      <div>
        <div className="flex justify-center">
          <img src={CheckMark} className="h-16 w-16" alt="Check Mark" />
        </div>
        <h2 className="text-center mt-6 font-bold text-xl">Awesome!</h2>
        <p className="text-center mt-4">
          Thank you! <br /> You now have full access to myNotifier!
        </p>
        <button
          onClick={closeModalHandler}
          className="w-full px-4 py-2 mx-auto mt-6 text-base font-medium text-white transition duration-500 ease-in-out transform bg-blue-600 border-blue-600 rounded-md items-centerw-full focus:shadow-outline focus:outline-none focus:ring-2 ring-offset-current ring-offset-2 hover:bg-blue-700 "
        >
          OK
        </button>
      </div>
    );
  }

  return (
    <div>
      {/* Modal */}
      <ReactModal
        isOpen={props.modalIsOpen}
        closeTimeoutMS={350}
        contentLabel="PRO Feature"
        className="modal w-full h-full sm:h-auto sm:w-2/3 md:w-1/2 xl:w-1/3 rounded shadow"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setSuccess(false);
          props.closeModalHandler();
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col p-10 bg-white rounded">{content}</div>
      </ReactModal>
    </div>
  );
};

export default GoProModal;
