import React from "react";

// Compontens
import LoadingOverlay from "../components/LoadingOverlay";
import SideBar from "../hoc/SideBar";
// import MainPageHeader from "../partials/MainPageHeader";
import DashboardContent from "../components/DashboardContent";
import MobileMenu from "../hoc/MobileMenu";

// Modules
import { withRouter } from "react-router-dom";
import ReactModal from "react-modal";

import GoPro from "../components/GoPro";

// Context
import { AuthContext } from "../context/Auth/context";
import { ProjectContext } from "../context/Project/context";
import { NotificationContext } from "../context/Notification/context";

const MainPage = (props) => {
  // Contexts
  const { authState } = React.useContext(AuthContext);
  const { notificationDispatch, notificationState } =
    React.useContext(NotificationContext);
  const { projectDispatch, projectState } = React.useContext(ProjectContext);

  // State
  const [loadedWidth, setLoadedWidth] = React.useState(false);
  const [showGoProModal, setShowGoProModal] = React.useState(false);
  // const [onMounAjax, setOnMountAjax] = React.useState(false);

  // Modal
  ReactModal.setAppElement("#root");

  let userProfile = null;
  let notificationsList = [];

  // On Mount
  React.useEffect(() => {
    if (!projectState.mountedHome) {
      // Check Width
      if (window.screen.width < 600 && !loadedWidth) {
        // setNotMobileModal(true);
      }



      setLoadedWidth(true);

    }
  }, [
    props.history,
    loadedWidth,
    authState.id,
    projectDispatch,
    authState,
    projectState.activeProject,
    notificationState.notifications,
    notificationDispatch,
    notificationState,
    projectState.mountedHome,
  ]);

  let loadingContent = null;

  if (notificationState.loadingNotifications) {
    loadingContent = <LoadingOverlay />;
  }

  return (
    <>
      {loadingContent}
      {/* Payment Modal */}

      {showGoProModal && (
        <GoPro
          modalIsOpen={showGoProModal}
          closeModalHandler={() => setShowGoProModal(false)}
        />
      )}

      {/* Mobile */}
      <MobileMenu />
      {/* Desktop Navigation */}
      <div className="h-screen w-full flex bg-gray-100">
        <SideBar />

        <div className="flex flex-col flex-1 w-full">
          {/* <MainPageHeader /> */}
          <DashboardContent
            userProfile={userProfile}
            setShowGoProModal={setShowGoProModal}
            notificationsList={notificationsList}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(MainPage);
