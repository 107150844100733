const data = {
  JavaScript: `import axios from 'axios'

  axios.post('https://api.mynotifier.app', {
    apiKey: '1111-2222-3333-4444', // Sign up for your own api key
    message: "New payment!", // Could be anything
    description: "A payment off $99 has been made", // Optional
    type: "success", // info, error, warning or success
    project: "" // Optional if you want to specify the project
  })`,
  Ruby: `url = URI.parse("https://api.mynotifier.app")
req = Net::HTTP::Post.new(url.path)
req.set_form_data({
  :apiKey => '1111-2222-3333-4444', # Sign up for your own api key
  :message => "Someone is at the door", # Could be anything
  :description => "At 22:00 someone triggered the camera by the frontdoor", # Optional
  :type => "info", # info, error, warning or success
  :project => "" # Optional if you want to specify the project
})`,
  Python: `
  requests.post('https://api.mynotifier.app', {
    "apiKey": '1111-2222-3333-4444', # Sign up for your own api key
    "message": "Someone is at the door", # Could be anything
    "description": "At 22:00 someone triggered the camera by the frontdoor", # Optional
    "type": "info", # info, error, warning or success
    "project": "" # Optional if you want to specify the project
  })
  `,
  Go: `func main() {
    http.PostForm("https://api.mynotifier.app", url.Values{
        "apiKey": {'1111-2222-3333-4444'}, // Sign up for your own api key
        "message": {"Server error"}, // Could be anything
        "description": {"Critical Server Error!"}, // Optional
        "type": {"error"}, // info, error, warning or success
        "project": {""} // Optional if you want to specify the project
    })
}`,
};

export default data;
