import React from "react";

// Components
import SideBar from "../hoc/SideBar";
import MobileMenu from "../hoc/MobileMenu";
// import default style
import "rsuite/dist/rsuite.min.css"; // or

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { DateRangePicker } from "rsuite";

// COntext
import { NotificationContext } from "../context/Notification/context";

const diffTypes = ["info", "warning", "error", "success"];

const Analytics = (props) => {
  // Context
  const { notificationState } = React.useContext(NotificationContext);
  //   const { projectDispatch, projectState } = React.useContext(ProjectContext);
  // State
  const [data, setData] = React.useState([]);

  const types = diffTypes.map((item, index) => {
    let statusColor = "#3b82f6";

    switch (item) {
      case diffTypes[0]:
        statusColor = "#3b82f6";
        break;

      case diffTypes[1]:
        statusColor = "#eab308";
        break;

      case diffTypes[2]:
        statusColor = "#ef4444";
        break;

      case diffTypes[3]:
        statusColor = "#22c55e";
        break;

      default:
        break;
    }

    return (
      <Line
        key={`${index}-line-item`}
        type="monotone"
        dataKey={item}
        stroke={statusColor}
        activeDot={{ r: 2 }}
      />
    );
  });

  const [dates, setDates] = React.useState([new Date(), new Date()]);

  const dateRangeHandler = (data) => {
    if (!data) {
      const future = new Date();
      future.setDate(future.getDate() - 7);
      setDates([future, new Date()]);
      return;
    }
    setDates(data);
  };

  React.useEffect(() => {
    const data = [];
    // loop for every day
    for (let day = dates[0]; day <= dates[1]; day.setDate(day.getDate() + 1)) {
      data.push({
        name: day.toDateString(),
        info: notificationState.notifications.filter(
          (x) =>
            x.type === "info" &&
            day.toDateString() === new Date(x.created).toDateString()
        ).length,
        warning: notificationState.notifications.filter(
          (x) =>
            x.type === "warning" &&
            day.toDateString() === new Date(x.created).toDateString()
        ).length,
        error: notificationState.notifications.filter(
          (x) =>
            x.type === "error" &&
            day.toDateString() === new Date(x.created).toDateString()
        ).length,
        success: notificationState.notifications.filter(
          (x) =>
            x.type === "success" &&
            day.toDateString() === new Date(x.created).toDateString()
        ).length,
      });
      // your day is here
    }

    setData(data);
  }, [dates, notificationState.notifications]);

  React.useEffect(() => {
    const future = new Date();
    future.setDate(future.getDate() - 7);
    setDates([future, new Date()]);
  }, []);

  //   if (loading) return <LoadingSpinner />;

  return (
    <>
      {/* Mobile */}
      <MobileMenu />
      {/* Desktop Navigation */}
      <div className="h-screen w-full flex bg-gray-100">
        <SideBar />
        <div className="flex flex-col flex-1 w-full">
          {/* <divPageHeader /> */}

          <main className="h-full overflow-y-auto">
            <div className="container px-6 mx-auto grid">
              <h2 className="my-6 pt-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                Analytics
              </h2>

              <div className="flex mb-4 ">
                <DateRangePicker
                  placeholder="Select daterange"
                  onChange={dateRangeHandler}
                />
                {/* <div className="flex mt-2 ml-4" >
                  <p className="m-0 font-bold" >{dates[0].toLocaleDateString()}</p>
                    <p className="mx-4 my-0" >-</p>
                  <p className="m-0 font-bold">{dates[1].toLocaleDateString()}</p>
                </div> */}
              </div>
              <div className="w-full " style={{ height: 450 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart width={500} height={300} data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {types}
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Analytics;
